/**
 * Nav List Item
 */
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import warningIcon from "../../../assets/Icons/warningIcon.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import clsx from "clsx";
import {
  List,
  ListItem,
  Collapse,
  Icon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from "@material-ui/core";
import { serverTimestamp, setDoc, doc } from "firebase/firestore";
import Moment from "moment";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../../config/firebase";
import { companyService, userService } from "_services";
import { protocolService } from "_services/protocol.service";
import { contractService } from "_services/contract.service";
import { kautionService } from "_services/kaution.service";
import { useAuth } from "context/AuthContext";
import { useHistory } from "react-router-dom";
import { BorderLeft } from "@mui/icons-material";

const styles = (theme) => ({
  textWhite: {
    color: `${theme.palette.common.white} !important`,
    "& span ,& a": {
      color: theme.palette.common.white,
      fontSize: "1.04rem ",
    },
  },
  ListItem: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    margin: " 2px 0",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.36)",
    },
  },
  desc: {
    color: "#969fa4",
    "& span": {
      fontSize: "0.75rem",
      color: "#969fa4",
    },
  },
  iconWrap: {
    fontFamily: "Material Icons Outlined",
    fontSize: "20px !important",
    display: "flex",
    alignItems: "center",
  },
  font9: {
    fontFamily: "Material Icons Outlined",
    fontSize: "9px !important",
  },
  truncate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  SubmenuOpen :{
    backgroundColor:"transparent",
    "& .sub-menu": {
      listStyle: "none",
      padding: "0px !important",

      "& li.MuiListItem-root": {
        padding: "0px !important" ,
        listStyle: "none",
        "& a": {
          padding: "5px 0px 5px 30px !important",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
      },
    },

  },
  menuOpen: {
    marginBottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderBottom: "0 !important",

    "& .sub-menu": {
      listStyle: "none",
      "& div.MuiListItem-root": {
        marginLeft: "30px",
        marginTop : 0,
        marginBottom : 0,
        borderRadius: 0,
        padding: "5px 10px 5px 10px",
        borderLeft: "1px solid rgba(255,255,255,0.4) !important",  
        "& a": {
          borderLeft: "none !important",
        },     
      },
      "& li": {
        padding: "0px 16px",
        listStyle: "none",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      },
      "& li.MuiListItem-root": {
        padding: "10px 16px",
        listStyle: "none",
        "& a": {
          marginLeft: "30px",
          padding: "5px 10px 5px 10px",
          borderLeft: "1px solid rgba(255,255,255,0.4) !important",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      },
    },
  },
  textLink: {
    padding: 0,
    "& a": {
      padding: "8px 20px 8px 16px",
      display: "block",
      "@media (max-width:1560px)": {
        padding: "10px 16px",
      },
    },
  },
  linkActive: {
    backgroundColor: "rgba(0, 0, 0, 0.2) !important",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.36) !!important",
    },
  },
  w100: {
    width: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
  },
});
const initialState = {
  alternative_billing: false,
  alternative_billing_adressNote: null,
  alternative_billing_location: null,
  alternative_billing_postal_code: null,
  alternative_billing_house_number: null,
  alternative_billing_street: null,
  alternative_billing_company_name: null,
  alternative_billing_last_name: null,
  alternative_billing_first_name: null,
  object_position: null,
  product_consumtion_price: null,

  alternative_billing_title: null,
  alternative_billing_gender: null,
  product_duration: "",
  address_note: null,
  product_updated_at: "",
  product_type: null,
  customer_location: null,
  postal_code: null,
  contractStatus: "active",
  contract_is_expired: false,
  id: null,
  created_at: null,
  created_by: null,
  created_remote: true,
  synched_remote: true,
  groupe_is_updated: false,
  idObject: null,
  idRealState: null,
  local_image_name: null,
  local_image_path: null,
  local_image_thumb: null,
  local_signature_name: null,
  local_signature_path: null,
  productIsExpired: false,
  pdf_created: false,
  product_id: null,
  idRealEstate: null,
  local_sepa_thumb: null,

  pdf_url: null,
  product_installation_position: "",
  customer_street: null,
  company_name: null,

  customer_mobile_phone: null,
  customer_email: null,
  customer_landline_phone: null,
  customer_birth_date: "",
  product_name: null,
  product_sealed: false,
  product_total_cost: null,

  product_renewal: null,
  customer_first_name: null,
  customer_last_name: null,
  customer_title: null,
  customer_gender: null,
  hasManyObject: false,
  product_counter_reading_2: null,
  sepa_check_box: false,
  sepa_iban: null,
  sepa_bic: null,
  sepa_account_holder: null,
  sepa_bank: null,
  product_counter_number: null,
  product_counter_reading: null,
  product_market_Location_number: null,
  product_counter_image_url: null,
  product_counter_type: null,
  product_move_date: null,
  product_move_date_supplier: null,
  product_the_earliest_possible_date: false,
  product_last_year_consumption: null,
  consumption: null,
  product_previous_customer_number: null,
  product_previous_supplier: null,
  product_new_move: false,
  product_change_supplier: false,

  realEstateObjectNumber: 0,
  send_email: null,
  company_id: null,
  send_pdf: false,
  persons_number: 0,
  sepa_signature_local_path: null,
  sepa_signature_name: null,
  sepa_signature_thumb: null,
  sepa_signature_time_stamp: null,
  sepa_signature_url: null,
  signature_thumb: null,
  signature_time_stamp: null,
  signature_url: null,
  id: null,
  customer_post_code: null,
  contract_leagl: false,
  updated_at: "",
  customer_house_number: null,
  realEstateCreated: false,
  product_base_price: null,
  updated_by: null,
};
const NavListItem = ({
  classes,
  menu,
  mobileToggleMenu,
  toggleThirdMenu,
  toggleMenu,
  toggleThirdMenuAndCloseSidebar,
  closeSidebar,
  toggleFourthMenu,
}) => {
  const [thirdMenuOpen, setThirdMenuOpen] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [type, setType] = useState("");
  const [path, setPath] = useState("");
  const [objectTosend, setObjectToSend] = useState(initialState);
  const [navigation, setNavigation] = useState(
    window.location.pathname.startsWith("/app/protocol/step") ||
      window.location.pathname.startsWith("/app/contract/selectProduct") ||
      window.location.pathname.startsWith("/app/kaution") ||
      window.location.pathname.startsWith("/app/protocol/rooms")
      ? false
      : true
  );
  //const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const currentUrl = window.location.pathname;
  useEffect(() => {
    fetchUserInfoAndCompany();
  }, []);
  const fetchUserInfoAndCompany = async () => {
    try {
      const userDoc = await userService.getUser(userService.getId());
      userDoc.forEach(async (doc) => {
        setUser(doc.data());

        if (doc.data().company_id) {
          let company = await companyService.getCompany(doc.data().company_id);

          setCompany(company);
        }
      });
    } catch (error) {
      console.error("Error fetching user info and company:", error);
    }
  };
  // On Toggle  Menu/
  const onMobileToggleMenu = () => {
    mobileToggleMenu(false);
  };

  const toggleAndCloseSidebar = () => {
    toggleMenu();
    if (closeSidebar) {
      closeSidebar();
    }
  };

  const SaveTheContract = async () => {
    const localStorageValue = JSON.parse(localStorage.getItem("globalState"));
    const updatedObject = fillObjectToSend(objectTosend, localStorageValue);
    addToDB(updatedObject);
  };

  const fillObjectToSend = (object1, object2) => {
    Object.keys(object2).forEach((key) => {
      if (object1.hasOwnProperty(key)) {
        object1[key] = object2[key];
      }
    });

    return object1;
  };

  const addToDB = (data) => {
    let id = data.id ? data.id : uuidv4();
    data.customer_birth_date = data.customer_birth_date
      ? Moment(data.customer_birth_date.toString()).format("DD.MM.YYYY")
      : null;
    data.product_last_year_consumption = data.product_last_year_consumption
      ? Moment(data.product_last_year_consumption.toString()).format(
          "DD.MM.YYYY"
        )
      : null;
    data.product_move_date = data.product_move_date
      ? Moment(data.product_move_date.toString()).format("DD.MM.YYYY")
      : null;
    try {
      setDoc(doc(db, "xc_contract", id), {
        ...data,
        id: id,
        is_from_web: true,
        created_at: data.created_at
          ? new Date(data?.created_at)
          : serverTimestamp(),
        updated_at: serverTimestamp(),
        product_updated_at: data.product_updated_at
          ? new Date(data?.product_updated_at)
          : null,
      }).then((result) => {
        localStorage.removeItem("globalState");
        localStorage.removeItem("visitedSteps");
        history.push(`${path}${type}`);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveProtocol = async (protocolToSave) => {
    protocolToSave = protocolService.initProtocolObject(
      protocolToSave,
      user.company_id
    );
    try {
      protocolService.createProtocol(protocolToSave).then((result) => {
        swal({
          title: "HINWEIS",
          text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
          button: "OK",
        }).then(async (willDelete) => {
          // Mark the callback function as async
          if (willDelete) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            window.location.href = `${path}`;
          }
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  const saveKaution = async (kautionToSave) => {
    console.log(kautionToSave);
    kautionToSave = kautionService.initKaution(kautionToSave, user.company_id);
    try {
      kautionService.createKaution(kautionToSave).then((result) => {
        swal({
          title: "HINWEIS",
          text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
          button: "OK",
        }).then(async (willDelete) => {
          // Mark the callback function as async
          if (willDelete) {
            localStorage.removeItem("Kaution");
            localStorage.removeItem("showCounters");

            // Navigate to the desired path
            window.location.href = path;
          } else {
            localStorage.removeItem("Kaution");
            localStorage.removeItem("showCounters");

            // Navigate to the desired path
            window.location.href = path;
          }
        });
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleConfirm = async () => {
    setShowAlert(false);

    switch (true) {
      case currentUrl.startsWith("/app/contract/selectProduct") ||
        currentUrl.startsWith("/app/protocol/step") ||
        currentUrl.startsWith("/app/protocol/rooms") ||
        currentUrl.startsWith("/app/kaution"):
        if (currentUrl.startsWith("/app/contract/selectProduct")) {
          const searchParams = new URLSearchParams(window.location.search);

          let currentType = searchParams.get("type");
          let globalState = JSON.parse(localStorage.getItem("globalState"));
          let contract = {};
          if (globalState.id) {
            contract = (
              await contractService.getContract(globalState.id)
            ).data();
            if (contract.pdf_created === true) {
              localStorage.removeItem("globalState");
              history.push("/app/dashboard");
            } else {
              swal({
                title: "HINWEIS",
                text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
                button: "OK",
              }).then((willDelete) => {
                if (
                  (currentType === "strom" || currentType === "gas") &&
                  type !== currentType
                ) {
                  SaveTheContract();
                } else {
                  history.push`${path}`;
                }
              });
            }
          } else {
            swal({
              title: "HINWEIS",
              text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
              button: "OK",
            }).then((willDelete) => {
              if (
                (currentType === "strom" || currentType === "gas") &&
                type !== currentType
              ) {
                SaveTheContract();
              } else {
                history.push(`${path}`);
              }
            });
          }
        }
        if (currentUrl.startsWith("/app/protocol/step")) {
          let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
          let protocolToverify = (
            await protocolService.getProtocol(protocolToSave.id)
          ).data();
          if (protocolToverify && protocolToverify.closed === true) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            localStorage.removeItem("showCounters");
            window.location.href = path;
          } else {
            try {
              let protocol = JSON.parse(localStorage.getItem("Protocol"));
              saveProtocol(protocol);
              localStorage.removeItem("Protocol");
              localStorage.removeItem("SuggestedTariffgas");
              localStorage.removeItem("SuggestedTariffstrom");
              localStorage.removeItem("SuggestedTariff");
              localStorage.removeItem("showCounters");
              //window.location.href = path;
            } catch (error) {
              console.log(error);
            }
          }
        }
        if (currentUrl.startsWith("/app/protocol/rooms")) {
          let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
          let protocolToverify = (
            await protocolService.getProtocol(protocolToSave.id)
          ).data();
          if (protocolToverify && protocolToverify.closed === true) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            localStorage.removeItem("showCounters");
            window.location.href = path;
          } else {
            try {
              let protocol = JSON.parse(localStorage.getItem("Protocol"));
              saveProtocol(protocol);
              localStorage.removeItem("Protocol");
              localStorage.removeItem("SuggestedTariffgas");
              localStorage.removeItem("SuggestedTariffstrom");
              localStorage.removeItem("SuggestedTariff");
              localStorage.removeItem("showCounters");
              //window.location.href = path;
            } catch (error) {
              console.log(error);
            }
          }
        }
        if (currentUrl.startsWith("/app/kaution")) {
          let kautionToSave = JSON.parse(localStorage.getItem("Kaution"));
          let kautionToVerify = (
            await kautionService.getKaution(kautionToSave.id)
          ).data();
          console.log(kautionToVerify);
          if (kautionToVerify && kautionToVerify.closed === true) {
            console.log("closed true ");
            /* localStorage.removeItem("Kaution");
            localStorage.removeItem("showCounters");
            window.location.href = path; */
          } else {
            try {
              let kaution = JSON.parse(localStorage.getItem("Kaution"));
              saveKaution(kaution);
            } catch (error) {
              console.log("Error saving Kaution:", error);
            }
          }
        }
        break;
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("Protocol");
    localStorage.removeItem("activeSteps");
    localStorage.removeItem("ObjectID");
    localStorage.removeItem("SuggestedTariff");
    localStorage.removeItem("showCounters");
    localStorage.removeItem("SuggestedTariffgas");
    localStorage.removeItem("SuggestedTariffstrom");
    localStorage.removeItem("Kaution");
    localStorage.removeItem("globalState");
    localStorage.removeItem("visitedSteps");
    setShowAlert(false);
    window.location.href = path;
  };

  const handleThirdButton = async () => {
    if (
      window.location.pathname.startsWith("/app/contract/selectProduct") ===
      true
    ) {
      let globalState = JSON.parse(localStorage.getItem("globalState"));
      let contract = {};
      if (globalState.id) {
        contract = (await contractService.getContract(globalState.id)).data();
        if (contract.pdf_created === true) {
          localStorage.removeItem("globalState");
          window.location.href = path;
        } else {
          contractService
            .deleteContract(contract.id)
            .then((result) => {
              localStorage.removeItem("globalState");
            })
            .then((result) => {
              window.location.href = path;
            });
        }
      } else {
        localStorage.removeItem("globalState");
        window.location.href = path;
      }
    }

    if (window.location.pathname.startsWith("/app/protocol/step")) {
      let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
      let protocolToverify = (
        await protocolService.getProtocol(protocolToSave.id)
      ).data();
      if (protocolToverify && protocolToverify.closed === true) {
        localStorage.removeItem("Protocol");
        localStorage.removeItem("SuggestedTariff");
        localStorage.removeItem("showCounters");
        localStorage.removeItem("SuggestedTariffgas");
        localStorage.removeItem("SuggestedTariffstrom");
        window.location.href = path;
      } else {
        protocolService.deleteProtocol(protocolToSave.id).then((result) => {
          if (result.success) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            window.location.href = path;
          }
        });
      }
    }
    if (window.location.pathname.startsWith("/app/protocol/rooms")) {
      let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
      let protocolToverify = (
        await protocolService.getProtocol(protocolToSave.id)
      ).data();
      if (protocolToverify && protocolToverify.closed === true) {
        localStorage.removeItem("Protocol");
        localStorage.removeItem("SuggestedTariff");
        localStorage.removeItem("showCounters");
        localStorage.removeItem("SuggestedTariffgas");
        localStorage.removeItem("SuggestedTariffstrom");
        window.location.href = path;
      } else {
        protocolService.deleteProtocol(protocolToSave.id).then((result) => {
          if (result.success) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            window.location.href = path;
          }
        });
      }
    }

    if (window.location.pathname.startsWith("/app/kaution")) {
      let kautionToSave = JSON.parse(localStorage.getItem("Kaution"));
      let kautionToverify = (
        await kautionService.getKaution(kautionToSave.id)
      ).data();
      if (kautionToverify && kautionToverify.closed === true) {
        localStorage.removeItem("Kaution");
        window.location.href = path;
      } else {
        kautionService.deleteKaution(kautionToSave.id).then((result) => {
          if (result.success) {
            localStorage.removeItem("Kaution");
            window.location.href = path;
          }
        });
      }
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleLinkOn = (pathUrl, index = null, childroute = null) => {
    const localStorageValue = JSON.parse(localStorage.getItem("Protocol"));
    const globalstate = JSON.parse(localStorage.getItem("globalState"));
    const kautionstate = JSON.parse(localStorage.getItem("Kaution"));
    let showAlert = false;
    if (childroute == "thirdchildroutes") {
      toggleFourthMenu(index);
    } else if (childroute == "secondchildroutes") {
      if (pathUrl == "") {
        return swal({
          title: "HINWEIS",
          text: "Diese Funktionen befinden sich in der Entwicklungsphase und werden demnächst für Sie freigeschaltet. Wir bitten um etwas Geduld.",
          button: "OK",
        });
      } else toggleThirdMenuAndCloseSidebar(index);
    }
    if (currentUrl.startsWith("/app/contract/selectProduct")) {
      if (globalstate && !globalstate.product_name) {
        localStorage.removeItem("globalState");

        setShowAlert(false);
        setPath(pathUrl);

        history.push(pathUrl);
      } else if (globalstate && globalstate.product_name) {
        setShowAlert(true);
        setPath(pathUrl);
      }
    } else if (
      currentUrl.startsWith("/app/protocol/step") &&
      localStorageValue != null &&
      localStorageValue.protocol_type !== ""
    ) {
      setShowAlert(true);
      setPath(pathUrl);
    } else if (
      currentUrl.startsWith("/app/protocol/rooms") &&
      localStorageValue != null &&
      localStorageValue.protocol_type !== ""
    ) {
      setShowAlert(true);
      setPath(pathUrl);
    } else if (
      currentUrl.startsWith("/app/kaution") &&
      kautionstate != null &&
      kautionstate.kaution_calculator != null &&
      kautionstate.kaution_calculator.deposit_amount != null &&
      kautionstate.kaution_calculator.deposit_amount !== ""
    ) {
      setShowAlert(true);
      setPath(pathUrl);
    } else {
      setShowAlert(false);
      localStorage.removeItem("globalState");
      localStorage.removeItem("visitedSteps");
      localStorage.removeItem("Protocol");
      localStorage.removeItem("ObjectID");
      localStorage.removeItem("SuggestedTariffgas");
      localStorage.removeItem("SuggestedTariff");
      localStorage.removeItem("SuggestedTariffstrom");
      localStorage.removeItem("showCounters");
      localStorage.removeItem("Kaution");

      setPath(pathUrl);
      history.push(`${pathUrl}`);
    }
  };

  // Get Active Item
  /* 	const getActiveItem = () => {
    let matchingMenuItem = null;
    this.routePaths.map((item, index) => {
      if (item.type !== "groupHeader") {
        if (item.path === location.pathname) {
          matchingMenuItem = item;
        }
      }
      return null;
    });
    return matchingMenuItem;
  } */

  /* 	const handleNavMenuClick = (item) => {
    const { thirdMenuOpen } = this.state;
    setThirdMenuOpen(!thirdMenuOpen)
    setActiveItem(item)
  	
  } */
  const handleLogout = () => {
    try {
      localStorage.removeItem("currentuser");
      localStorage.removeItem("switchedcustomer");
      localStorage.removeItem("protocol");
      localStorage.removeItem("globalState");
      localStorage.removeItem("activeSteps");
      localStorage.removeItem("visitedSteps");
      localStorage.removeItem("VISITEDcounter");
      localStorage.removeItem("filters");
      window.location.href = "/signin";
    } catch (error) {
      console.log("Failed to log out", error);
    }
  };
  return (
    <>
      {menu.child_routes !== null && menu.fullPageMenu === false && (
        <>
          {menu.menu_title == "App Notifikation" ? (
            (user?.role === "admin" || user?.role === "tester") && (
              <>
                <li>
                  <ListItem
                    disableRipple
                    button
                    component="div"
                    onClick={() => toggleMenu()}
                    className={clsx(
                      classes.textWhite,
                      classes.ListItem,
                      {
                        "active-menu": menu.isMenuOpen,
                        [classes.menuOpen]: menu.isMenuOpen,
                      },
                      "list-item"
                    )}
                  >
                    <div className={classes.w100}>
                      <div className={classes.flexCenter}>
                        <Box component="span" className={classes.iconWrap}>
                          {menu.image && (
                            <img
                              src={require(`assets/Icons/${menu.image}`)}
                              alt="Icon"
                              className={`${
                                menu.style && menu.style
                              } icon-side`}
                            />
                          )}
                          {menu.icon && menu.icon}
                        </Box>
                        <ListItemText
                          primary={menu.menu_title}
                          style={{ paddingLeft: 12 }}
                        />
                        {menu.isMenuOpen ? (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_up
                          </Icon>
                        ) : (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_down
                          </Icon>
                        )}
                      </div>
                    </div>
                  </ListItem>
                  <Collapse
                    in={menu.isMenuOpen}
                    timeout="auto"
                    unmountOnExit
                    className={clsx(classes.textWhite, {
                      [classes.menuOpen]: menu.isMenuOpen,
                    })}
                  >
                    <List component="ul" className="sub-menu">
                      {menu.child_routes.map((subMenu, index) => {
                        if (
                          subMenu.third_child_routes !== null &&
                          subMenu.fullPageMenu === false
                        ) {
                          return (
                            <li key={index}>
                              <ListItem
                                disableRipple
                                button
                                component="div"
                                onClick={() => toggleThirdMenu(index)}
                                className={clsx(
                                  classes.textWhite,
                                  {
                                    [classes.menuOpen]: subMenu.isMenuOpen,
                                  },
                                  "list-item"
                                )}
                              >
                                <div className={classes.w100}>
                                  <div className={classes.flexCenter}>
                                    <ListItemText
                                      primary={subMenu.menu_title}
                                      style={{ paddingLeft: 20 }}
                                    />
                                    {subMenu.isMenuOpen ? (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_up
                                      </Icon>
                                    ) : (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_down
                                      </Icon>
                                    )}
                                  </div>
                                </div>
                              </ListItem>
                              <Collapse
                                in={subMenu.isMenuOpen}
                                timeout="auto"
                                unmountOnExit
                                className={clsx(classes.textWhite, {
                                  [classes.menuOpen]: subMenu.isMenuOpen,
                                })}
                              >
                                <List component="ul" className="sub-menu">
                                  {subMenu.third_child_routes.map(
                                    (thirdMenu, fourthindex) => (
                                      <ListItem
                                        key={fourthindex}
                                        disableRipple
                                        button
                                        component="li"
                                        onClick={() =>
                                          handleLinkOn(
                                            thirdMenu.path,
                                            fourthindex,
                                            "thirdchildroutes"
                                          )
                                        }
                                        className={clsx(
                                          classes.textWhite,
                                          {
                                            [classes.menuOpen]:
                                              subMenu.isMenuOpen,
                                          },
                                          "list-item"
                                        )}
                                      >
                                        <a
                                          className={`link ${classes.flexCenter}`}
                                        >
                                          {thirdMenu.menu_title}
                                        </a>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </Collapse>
                            </li>
                          );
                        }

                        return (
                          <ListItem
                            key={index}
                            disableRipple
                            button
                            component="li"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            onClick={() =>
                              handleLinkOn(
                                subMenu.path === "/app/user"
                                  ? `${subMenu.path}/${userService?.getId()}`
                                  : subMenu.path === "/app/company" ||
                                    subMenu.path === "/app/smtp"
                                  ? `${subMenu.path}/${user?.company_id}`
                                  : subMenu.path,
                                index,
                                "secondchildroutes"
                              )
                            }
                            className={classes.childList}
                          >
                            <a className={`link ${classes.flexCenter}`}>
                              {subMenu.menu_title}
                            </a>
                            {/* <NavLink
                              exact={!navigation}
                              to={
                                navigation
                                  ? "#"
                                  : subMenu.params
                                    ? {
                                      pathname: subMenu.path,
                                      state: { type: subMenu.params },
                                    }
                                    : subMenu.path === "/app/user"
                                      ? `${subMenu.path}/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                        ? `${subMenu.path}/${user?.company_id}`
                                        : subMenu.path
                              }
                            >
                              {subMenu.menu_title}
                            </NavLink> */}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </li>
                {menu.dashed && <div className="dashed-line"></div>}
                {showAlert && (
                  <Dialog
                    open={showAlert}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogContent>
                      <Box textAlign="center">
                        <img src={warningIcon} alt="warning icon" />
                        <h4
                          style={{
                            fontSize: "1.875em",
                            fontWeight: "600",
                            textAlign: "center",
                            color: "#888",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          ACHTUNG
                        </h4>
                      </Box>
                      <div
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "normal",
                          textAlign: "center",
                          color: "#888",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Sie sind im Begriff das aktuelle Formular zu verlassen.
                        Wie möchten Sie vorgehen?
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <button
                        onClick={handleCancel}
                        className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                      >
                        Ohne speichern verlassen
                      </button>
                      <button
                        onClick={handleThirdButton}
                        className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                      >
                        Löschen und Schließen
                      </button>
                      <button
                        onClick={handleConfirm}
                        className="button-save w-full custom-icon d-flex align-items-stretch"
                      >
                        Speichern und Schließen
                      </button>
                    </DialogActions>
                    <button
                      onClick={handleClose}
                      className="close-button"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        border: "none",
                        background: "none",
                      }}
                    >
                      <HighlightOffIcon style={{ fontSize: 25 }} />
                    </button>
                  </Dialog>
                )}
              </>
            )
          ) : (
            <></>
          )}
          {menu.menu_title == "Auftragsübersicht" && (
            <>
              <li>
                <ListItem
                  disableRipple
                  button
                  component="div"
                  onClick={() => toggleMenu()}
                  className={clsx(
                    classes.textWhite,
                    classes.ListItem,
                    {
                      "active-menu": menu.isMenuOpen,
                      [classes.menuOpen]: menu.isMenuOpen,
                    },
                    "list-item"
                  )}
                >
                  <div className={classes.w100}>
                    <div className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                      {menu.isMenuOpen ? (
                        <Icon style={{ fontSize: 20, width: 15 }}>
                          arrow_drop_up
                        </Icon>
                      ) : (
                        <Icon style={{ fontSize: 20, width: 15 }}>
                          arrow_drop_down
                        </Icon>
                      )}
                    </div>
                  </div>
                </ListItem>
                <Collapse
                  in={menu.isMenuOpen}
                  timeout="auto"
                  unmountOnExit
                  className={clsx(classes.textWhite, {
                    [classes.menuOpen]: menu.isMenuOpen,
                  })}
                >
                  <List component="ul" className="sub-menu">
                    {menu.child_routes.map((subMenu, index) => {
                      if (subMenu.path == "/app/archive/list") {
                        if (
                          user?.role === "admin" ||
                          user?.role === "tester" ||
                          company.company_account_type == "premium"
                        ) {
                          return (
                            <ListItem
                              key={index}
                              disableRipple
                              button
                              component="li"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              onClick={() =>
                                handleLinkOn(
                                  subMenu.path,
                                  index,
                                  "secondchildroutes"
                                )
                              }
                              className={classes.childList}
                            >
                              {/* <NavLink
                                  exact={!navigation}
                                  to={
                                    navigation
                                      ? "#"
                                      : subMenu.params
                                        ? {
                                          pathname: subMenu.path,
                                          state: { type: subMenu.params },
                                        }
                                        : subMenu.path === "/app/user"
                                          ? `${subMenu.path}/${userService?.getId()}`
                                          : subMenu.path === "/app/company" ||
                                            subMenu.path === "/app/smtp"
                                            ? `${subMenu.path}/${user?.company_id}`
                                            : subMenu.path
                                  }
                                >
                                  {subMenu.menu_title}
                                </NavLink> */}
                              <a className={`link ${classes.flexCenter}`}>
                                {subMenu.menu_title}
                              </a>
                            </ListItem>
                          );
                        } else {
                          return <></>;
                        }
                      }
                      return (
                        <ListItem
                          key={index}
                          disableRipple
                          button
                          component="li"
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          onClick={() =>
                            handleLinkOn(
                              subMenu.path,
                              index,
                              "secondchildroutes"
                            )
                          }
                          className={classes.childList}
                        >
                          <a className={`link ${classes.flexCenter}`}>
                            {subMenu.menu_title}
                          </a>
                          {/* <NavLink
                            exact={!navigation}
                            to={
                              navigation
                                ? "#"
                                : subMenu.params
                                  ? {
                                    pathname: subMenu.path,
                                    state: { type: subMenu.params },
                                  }
                                  : subMenu.path === "/app/user"
                                    ? `${subMenu.path}/${userService?.getId()}`
                                    : subMenu.path === "/app/company" ||
                                      subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path
                            }
                          >
                            {subMenu.menu_title}
                          </NavLink> */}
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </li>
              {menu.dashed && <div className="dashed-line"></div>}
              {showAlert && (
                <Dialog
                  open={showAlert}
                  onClose={handleClose}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    <Box textAlign="center">
                      <img src={warningIcon} alt="warning icon" />
                      <h4
                        style={{
                          fontSize: "1.875em",
                          fontWeight: "600",
                          textAlign: "center",
                          color: "#888",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        ACHTUNG
                      </h4>
                    </Box>
                    <div
                      style={{
                        fontSize: "1.5em",
                        fontWeight: "300",
                        lineHeight: "normal",
                        textAlign: "center",
                        color: "#888",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Sie sind im Begriff das aktuelle Formular zu verlassen.
                      Wie möchten Sie vorgehen?
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <button
                      onClick={handleCancel}
                      className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                    >
                      Ohne speichern verlassen
                    </button>
                    <button
                      onClick={handleThirdButton}
                      className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                    >
                      Löschen und Schließen
                    </button>
                    <button
                      onClick={handleConfirm}
                      className="button-save w-full custom-icon d-flex align-items-stretch"
                    >
                      Speichern und Schließen
                    </button>
                  </DialogActions>
                  <button
                    onClick={handleClose}
                    className="close-button"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <HighlightOffIcon style={{ fontSize: 25 }} />
                  </button>
                </Dialog>
              )}
            </>
          )}

          {menu.menu_title !== "App Notifikation" &&
            menu.menu_title !== "Auftragsübersicht" && (
              <>
                <li>
                  <ListItem
                    disableRipple
                    button
                    component="div"
                    onClick={() => toggleMenu()}
                    className={clsx(
                      classes.textWhite,
                      classes.ListItem,
                      {
                        "active-menu": menu.isMenuOpen,
                        [classes.menuOpen]: menu.isMenuOpen,
                      },
                      "list-item"
                    )}
                  >
                    <div className={classes.w100}>
                      <div className={classes.flexCenter}>
                        <Box component="span" className={classes.iconWrap}>
                          {menu.image && (
                            <img
                              src={require(`assets/Icons/${menu.image}`)}
                              alt="Icon"
                              className={`${
                                menu.style && menu.style
                              } icon-side`}
                            />
                          )}
                          {menu.icon && menu.icon}
                        </Box>
                        <ListItemText
                          primary={menu.menu_title}
                          style={{ paddingLeft: 20 }}
                        />
                        {menu.isMenuOpen ? (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_up
                          </Icon>
                        ) : (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_down
                          </Icon>
                        )}
                      </div>
                    </div>
                  </ListItem>
                  <Collapse
                    in={menu.isMenuOpen}
                    timeout="auto"
                    unmountOnExit
                    className={clsx(classes.textWhite, {
                      [classes.menuOpen]: menu.isMenuOpen,
                    })}
                  >
                    <List component="ul" className="sub-menu">
                      {menu.child_routes.map((subMenu, index) => {
                        if (subMenu.path == "/app/support/switch_user") {
                          if (
                            user?.role === "admin" ||
                            user?.role === "tester"
                          ) {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={clsx(classes.childList,
                                  {
                                    [classes.linkActive]: menu.isMenuOpen,
                                  },
                                )}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                                {/* <NavLink
                                  exact={!navigation}
                                  to={
                                    navigation
                                      ? "#"
                                      : subMenu.params
                                        ? {
                                          pathname: subMenu.path,
                                          state: { type: subMenu.params },
                                        }
                                        : subMenu.path === "/app/user"
                                          ? `${subMenu.path}/${userService?.getId()}`
                                          : subMenu.path === "/app/company" ||
                                            subMenu.path === "/app/smtp"
                                            ? `${subMenu.path}/${user?.company_id}`
                                            : subMenu.path
                                  }
                                >
                                  {subMenu.menu_title}
                                </NavLink> */}
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        } else if (subMenu.path == "/app/support/ticket") {
                          if (
                            (user?.role != "admin" &&
                              company.company_account_type == "premium") ||
                            user?.role === "admin"
                          ) {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={classes.childList}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                                {/* <NavLink
                                  exact={!navigation}
                                  to={
                                    navigation
                                      ? "#"
                                      : subMenu.params
                                        ? {
                                          pathname: subMenu.path,
                                          state: { type: subMenu.params },
                                        }
                                        : subMenu.path === "/app/user"
                                          ? `${subMenu.path}/${userService?.getId()}`
                                          : subMenu.path === "/app/company" ||
                                            subMenu.path === "/app/smtp"
                                            ? `${subMenu.path}/${user?.company_id}`
                                            : subMenu.path
                                  }
                                >
                                  {subMenu.menu_title}
                                </NavLink> */}
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (subMenu.path == "/app/administration/tickets") {
                          if (user?.role === "admin") {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={classes.childList}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                                {/* <NavLink
                                exact={!navigation}
                                to={
                                  navigation
                                    ? "#"
                                    : subMenu.params
                                      ? {
                                        pathname: subMenu.path,
                                        state: { type: subMenu.params },
                                      }
                                      : subMenu.path === "/app/user"
                                        ? `${subMenu.path}/${userService?.getId()}`
                                        : subMenu.path === "/app/company" ||
                                          subMenu.path === "/app/smtp"
                                          ? `${subMenu.path}/${user?.company_id}`
                                          : subMenu.path
                                }
                              >
                                {subMenu.menu_title}
                              </NavLink> */}
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (subMenu.menu_title == "Unternehmen") {
                          if (
                            user?.role === "makler" ||
                            user?.role === "admin" ||
                            user?.role === "tester"
                          ) {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={classes.childList}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                                {/* <NavLink
                                exact={!navigation}
                                to={
                                  navigation
                                    ? "#"
                                    : subMenu.params
                                      ? {
                                        pathname: subMenu.path,
                                        state: { type: subMenu.params },
                                      }
                                      : subMenu.path === "/app/user"
                                        ? `${subMenu.path}/${userService?.getId()}`
                                        : subMenu.path === "/app/company" ||
                                          subMenu.path === "/app/smtp"
                                          ? `${subMenu.path}/${user?.company_id}`
                                          : subMenu.path
                                }
                              >
                                {subMenu.menu_title}
                              </NavLink> */}
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (
                          subMenu.menu_title == "Produktgruppen" ||
                          subMenu.menu_title == "Rechtevergabe" ||
                          subMenu.menu_title == "Emaileinstellungen" ||
                          subMenu.path == "/app/realestate/import" ||
                          subMenu.path == "/app/realestate/export"
                        ) {
                          if (
                            (user?.role === "admin" ||
                              user?.role === "tester" ||
                              user?.role === "makler") &&
                            (company?.company_account_type === "premium" ||
                              company?.company_account_type === "power")
                          ) {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={classes.childList}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                                {/* <NavLink
                                exact={!navigation}
                                to={
                                  !navigation &&(subMenu.params
                                    ? {
                                      pathname: subMenu.path,
                                      state: { type: subMenu.params },
                                    }
                                    : subMenu.path === "/app/user"
                                      ? `${subMenu.path}/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                        ? `${subMenu.path}/${user?.company_id}`
                                        : subMenu.path) 
                                }
                              >
                                {subMenu.menu_title}
                              </NavLink> */}
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (
                          subMenu.third_child_routes !== null &&
                          subMenu.fullPageMenu === false
                        ) {
                          if (subMenu.menu_title == "Produkt Importieren") {
                            if (user?.role == "admin")
                              return (
                                <li
                                  key={index}
                                >
                                  <ListItem
                                    disableRipple
                                    button
                                    component="div"
                                    onClick={() => toggleThirdMenu(index)}
                                    className={clsx(
                                      classes.textWhite,
                                      classes.childList,
                                      {
                                        [classes.SubmenuOpen]: subMenu.isMenuOpen,
                                      },
                                      "list-item"
                                    )}
                                  >
                                    <div className={classes.w100}>
                                      <div className={clsx(classes.flexCenter, classes.childList)}>
                                        <ListItemText
                                          primary={subMenu.menu_title}
                                        />
                                        {subMenu.isMenuOpen ? (
                                          <Icon
                                            style={{ fontSize: 20, width: 35 }}
                                          >
                                            arrow_drop_up
                                          </Icon>
                                        ) : (
                                          <Icon
                                            style={{ fontSize: 20, width: 35 }}
                                          >
                                            arrow_drop_down
                                          </Icon>
                                        )}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <Collapse
                                    in={subMenu.isMenuOpen}
                                    timeout="auto"
                                    unmountOnExit
                                    className={clsx(classes.textWhite, {
                                      [classes.SubmenuOpen]: subMenu.isMenuOpen,
                                    })}
                                  >
                                    <List component="ul" className="sub-menu">
                                      {subMenu.third_child_routes.map(
                                        (thirdMenu, fourthindex) => (
                                          <ListItem
                                            key={fourthindex}
                                            disableRipple
                                            button
                                            component="li"
                                            onClick={() =>
                                              handleLinkOn(
                                                thirdMenu.path,
                                                fourthindex,
                                                "thirdchildroutes"
                                              )
                                            }
                                            className={clsx(
                                              classes.textWhite,
                                                {
                                                  [classes.linkActive]: menu.isMenuOpen,
                                                },
                                              {
                                                [classes.SubmenuOpen]:
                                                  subMenu.isMenuOpen,
                                              },
                                              "list-item"
                                            )}
                                          >
                                            <a
                                              className={`link ${classes.flexCenter}`}
                                            >
                                              {thirdMenu.menu_title}
                                            </a>
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Collapse>
                                </li>
                              );
                            else return <></>;
                          }
                          if (subMenu.menu_title == "Mitarbeiter") {
                            if (
                              (user?.role === "admin" ||
                                user?.role === "tester" ||
                                user?.role === "makler") &&
                              (company?.company_account_type === "premium" ||
                                company?.company_account_type === "power")
                            ) {
                              return (
                                <li
                                  key={index}
                                >
                                  <ListItem
                                    disableRipple
                                    button
                                    component="div"
                                    onClick={() => toggleThirdMenu(index)}
                                    className={clsx(
                                      classes.textWhite,
                                      {
                                        [classes.SubmenuOpen]: subMenu.isMenuOpen,
                                      },
                                      "list-item"
                                    )}
                                  >
                                    <div className={classes.w100}>
                                      <div className={classes.flexCenter}>
                                        <ListItemText
                                          primary={subMenu.menu_title}
                                        />
                                        {subMenu.isMenuOpen ? (
                                          <Icon
                                            style={{ fontSize: 20, width: 35 }}
                                          >
                                            arrow_drop_up
                                          </Icon>
                                        ) : (
                                          <Icon
                                            style={{ fontSize: 20, width: 35 }}
                                          >
                                            arrow_drop_down
                                          </Icon>
                                        )}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <Collapse
                                    in={subMenu.isMenuOpen}
                                    timeout="auto"
                                    unmountOnExit
                                    className={clsx(classes.textWhite, {
                                      [classes.SubmenuOpen]: subMenu.isMenuOpen,
                                    })}
                                  >
                                    <List component="ul" className="sub-menu">
                                      {subMenu.third_child_routes.map(
                                        (thirdMenu, fourthindex) => (
                                          <ListItem
                                            key={fourthindex}
                                            disableRipple
                                            button
                                            component="li"
                                            onClick={() =>
                                              handleLinkOn(
                                                thirdMenu.path,
                                                fourthindex,
                                                "thirdchildroutes"
                                              )
                                            }
                                            className={clsx(
                                              classes.textWhite,
                                              {
                                                [classes.SubmenuOpen]:
                                                  subMenu.isMenuOpen,
                                              },
                                              "list-item"
                                            )}
                                          >
                                            <a
                                              className={`link ${classes.flexCenter}`}
                                            >
                                              {thirdMenu.menu_title}
                                            </a>
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Collapse>
                                </li>
                              );
                            } else return <></>;
                          }
                          return (
                            <li key={index}>
                              <ListItem
                                disableRipple
                                button
                                component="div"
                                onClick={() => toggleThirdMenu(index)}
                                className={clsx(
                                  classes.textWhite,
                                  classes.ListItem,
                                  {
                                    [classes.menuOpen]: subMenu.isMenuOpen,
                                  },
                                  "list-item"
                                )}
                              >
                                <div className={classes.w100}>
                                  <div className={classes.flexCenter}>
                                    <ListItemText
                                      primary={subMenu.menu_title}
                                      style={{ paddingLeft: 20 }}
                                    />
                                    {subMenu.isMenuOpen ? (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_up
                                      </Icon>
                                    ) : (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_down
                                      </Icon>
                                    )}
                                  </div>
                                </div>
                              </ListItem>
                              <Collapse
                                in={subMenu.isMenuOpen}
                                timeout="auto"
                                unmountOnExit
                                className={clsx(classes.textWhite, {
                                  [classes.menuOpen]: subMenu.isMenuOpen,
                                })}
                              >
                                <List component="ul" className="sub-menu">
                                  {subMenu.third_child_routes.map(
                                    (thirdMenu, fourthindex) => (
                                      <ListItem
                                        key={fourthindex}
                                        disableRipple
                                        button
                                        component="li"
                                        onClick={() =>
                                          handleLinkOn(
                                            thirdMenu.path,
                                            fourthindex,
                                            "thirdchildroutes"
                                          )
                                        }
                                        className={clsx(
                                          classes.textWhite,
                                          classes.ListItem,
                                          {
                                            [classes.menuOpen]:
                                              subMenu.isMenuOpen,
                                          },
                                          "list-item"
                                        )}
                                      >
                                        <a
                                          className={`link ${classes.flexCenter}`}
                                        >
                                          {thirdMenu.menu_title}
                                        </a>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </Collapse>
                            </li>
                          );
                        }

                        return (
                          <ListItem
                            key={index}
                            disableRipple
                            button
                            component="li"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            onClick={() =>
                              handleLinkOn(
                                subMenu.path === "/app/user"
                                  ? `${subMenu.path}/${userService?.getId()}`
                                  : subMenu.path === "/app/company" ||
                                    subMenu.path === "/app/smtp"
                                  ? `${subMenu.path}/${user?.company_id}`
                                  : subMenu.path,
                                index,
                                "secondchildroutes"
                              )
                            }
                            className={classes.childList}
                          >
                            <a className={`link ${classes.flexCenter}`}>
                              {subMenu.menu_title}
                            </a>
                            {/* <NavLink
                            exact={!navigation}
                            to={
                              navigation
                                ? "#"
                                : subMenu.params
                                  ? {
                                    pathname: subMenu.path,
                                    state: { type: subMenu.params },
                                  }
                                  : subMenu.path === "/app/user"
                                    ? `${subMenu.path}/${userService?.getId()}`
                                    : subMenu.path === "/app/company" ||
                                      subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path
                            }
                          >
                            {subMenu.menu_title}
                          </NavLink> */}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </li>
                {menu.dashed && <div className="dashed-line"></div>}
                {showAlert && (
                  <Dialog
                    open={showAlert}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogContent>
                      <Box textAlign="center">
                        <img src={warningIcon} alt="warning icon" />
                        <h4
                          style={{
                            fontSize: "1.875em",
                            fontWeight: "600",
                            textAlign: "center",
                            color: "#888",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          ACHTUNG
                        </h4>
                      </Box>
                      <div
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "normal",
                          textAlign: "center",
                          color: "#888",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Sie sind im Begriff das aktuelle Formular zu verlassen.
                        Wie möchten Sie vorgehen?
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <button
                        onClick={handleCancel}
                        className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                      >
                        Ohne speichern verlassen
                      </button>
                      <button
                        onClick={handleThirdButton}
                        className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                      >
                        Löschen und Schließen
                      </button>
                      <button
                        onClick={handleConfirm}
                        className="button-save w-full custom-icon d-flex align-items-stretch"
                      >
                        Speichern und Schließen
                      </button>
                    </DialogActions>
                    <button
                      onClick={handleClose}
                      className="close-button"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        border: "none",
                        background: "none",
                      }}
                    >
                      <HighlightOffIcon style={{ fontSize: 25 }} />
                    </button>
                  </Dialog>
                )}
              </>
            )}
        </>
      )}
      {menu.child_routes === null && (
        <>
          {menu.path == "/app/protocol-pattern" ? (
            (user?.role === "admin" ||
              user?.role === "tester" ||
              user?.role === "makler") &&
            (company?.company_account_type === "premium" ||
              company?.company_account_type === "power") && (
              <ListItem
                disableRipple
                button
                component="li"
                className={clsx(
                  classes.textWhite,
                  classes.textLink,
                  classes.ListItem,
                  {
                    [classes.linkActive]: menu.isMenuOpen,
                  },
                  "list-item"
                )}
                onClick={() => toggleAndCloseSidebar()}
              >
                <div
                  className={classes.w100}
                  onClick={
                    menu.menu_title === "Ausloggen"
                      ? handleLogout
                      : () => handleLinkOn(menu.path)
                  }
                >
                  <a>
                    <Box className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                    </Box>
                  </a>
                </div>
              </ListItem>
            )
          ) : (
            <></>
          )}
          {menu.path == "/app/promocode/overview" ? (
            (user?.role === "admin" ||
              (user?.role != "admin" &&
                company?.company_account_type === "basic")) && (
              <ListItem
                disableRipple
                button
                component="li"
                className={clsx(
                  classes.textWhite,
                  classes.textLink,
                  classes.ListItem,
                  {
                    [classes.linkActive]: menu.isMenuOpen,
                  },
                  "list-item"
                )}
                onClick={() => toggleAndCloseSidebar()}
              >
                <div
                  className={classes.w100}
                  onClick={
                    menu.menu_title === "Ausloggen"
                      ? handleLogout
                      : () => handleLinkOn(menu.path)
                  }
                >
                  <a>
                    <Box className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                    </Box>
                    {menu.desc && (
                      <Box
                        fontSize="body1.fontSize"
                        className={`desc-wrap ${classes.truncate} ${classes.desc}`}
                        display="block"
                      >
                        {menu.content}
                      </Box>
                    )}
                  </a>
                </div>
              </ListItem>
            )
          ) : (
            <></>
          )}
          {menu.path !== "/app/protocol-pattern" &&
            menu.path !== "/app/promocode/overview" && (
              <ListItem
                disableRipple
                button
                component="li"
                className={clsx(
                  classes.textWhite,
                  classes.textLink,
                  classes.ListItem,
                  {
                    [classes.linkActive]: menu.isMenuOpen,
                  },
                  "list-item"
                )}
                onClick={() => toggleAndCloseSidebar()}
              >
                <div
                  className={classes.w100}
                  onClick={
                    menu.menu_title === "Ausloggen"
                      ? handleLogout
                      : () => handleLinkOn(menu.path)
                  }
                >
                  <a>
                    <Box className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                    </Box>
                    {menu.desc && (
                      <Box
                        fontSize="body1.fontSize"
                        className={`desc-wrap ${classes.truncate} ${classes.desc}`}
                        display="block"
                      >
                        {menu.content}
                      </Box>
                    )}
                  </a>
                </div>
              </ListItem>
            )}
          {menu.dashed && <div className="dashed-line"></div>}
          {showAlert && (
            <Dialog
              open={showAlert}
              onClose={handleClose}
              fullWidth
              maxWidth="sm"
            >
              <DialogContent>
                <Box textAlign="center">
                  <img src={warningIcon} alt="warning icon" />
                  <h4
                    style={{
                      fontSize: "1.875em",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#888",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    ACHTUNG
                  </h4>
                </Box>
                <div
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "300",
                    lineHeight: "normal",
                    textAlign: "center",
                    color: "#888",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Sie sind im Begriff das aktuelle Formular zu verlassen. Wie
                  möchten Sie vorgehen?
                </div>
              </DialogContent>
              <DialogActions>
                <button
                  onClick={handleCancel}
                  className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                >
                  Ohne speichern verlassen
                </button>
                <button
                  onClick={handleThirdButton}
                  className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                >
                  Löschen und Schließen
                </button>
                <button
                  onClick={handleConfirm}
                  className="button-save w-full custom-icon d-flex align-items-stretch"
                >
                  Speichern und Schließen
                </button>
              </DialogActions>
              <button
                onClick={handleClose}
                className="close-button"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  border: "none",
                  background: "none",
                }}
              >
                <HighlightOffIcon style={{ fontSize: 25 }} />
              </button>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(NavListItem);
