/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


const AsyncDashboardComponent = Loadable({
	loader: () => import("routes/Dashboard/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncNewDashboardComponent = Loadable({
	loader: () => import("routes/Dashboard/Dashboard1/Dashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolOverviewComponent = Loadable({
	loader: () => import("routes/Dashboard/protocol_overview"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncKautionOverviewComponent = Loadable({
	loader: () => import("routes/Kaution_Contract/showKaution"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolListComponent = Loadable({
	loader: () => import("routes/Dashboard/ProtocolList/Protocol"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncContractListComponent = Loadable({
	loader: () => import("routes/Dashboard/ContractList/Contract"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncDeffectListComponent = Loadable({
	loader: () => import("routes/DeffectFilter/DeffectListOverview/deffect_overview"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncDeffectDetailsComponent = Loadable({
	loader: () => import("routes/DeffectFilter/DeffectDetails/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncArchiveListComponent = Loadable({
	loader: () => import("routes/Dashboard/ArchiveList/Archiv"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncKautionListComponent = Loadable({
	loader: () => import("routes/Dashboard/KautionList/Kaution"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncProtocolControlOverviewComponent = Loadable({
	loader: () => import("routes/Dashboard/ProtocolControl"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncContractOverviewComponent = Loadable({
	loader: () => import("routes/Dashboard/Showproduct"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncPropertiesComponent = Loadable({
	loader: () => import("routes/Properties/RealEstate"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncImportRealEstateComponent = Loadable({
	loader: () => import("routes/Properties/Import_RealEstate/Import_realEstate_step"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncExportRealEstateComponent = Loadable({
	loader: () => import("routes/Properties/Export_RealEstate/export_real_estate"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncCreatePropertyComponent = Loadable({
	loader: () => import("routes/Properties/Create_property"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncCreateAppartementComponent = Loadable({
	loader: () => import("routes/Properties/Create_apartment"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynclistAppartementComponent = Loadable({
	loader: () => import("routes/Properties/Appartements"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncTarifCalculatorComponent = Loadable({
	loader: () => import("routes/Tarif_Calculator/tarifCalculator"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolComponent = Loadable({
	loader: () => import("routes/Protokol/stepper"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolRoomsComponent = Loadable({
	loader: () => import("routes/Protokol/Roomdetails"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolRoomsComponent2 = Loadable({
	loader: () => import("routes/Protokol/room-detail"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolRoomsComponent3 = Loadable({
	loader: () => import("routes/Protokol/RoomDetails/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncKautionComponent = Loadable({
	loader: () => import("routes/Kaution_Contract/steps"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncKautionSettingsComponent = Loadable({
	loader: () => import("routes/KautionSettings/KautionSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncContractComponent = Loadable({
	loader: () => import("routes/Contract/stepper"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncImpressumComponent = Loadable({
	loader: () => import("routes/LegalInfo/Impressum"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

 const AsyncAddPromoCodeComponent = Loadable({
 	loader: () => import("routes/promo-code/redirection"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncPolicyComponent = Loadable({
	loader: () => import("routes/LegalInfo/PrivacyInfo"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncTermOfUseComponent = Loadable({
	loader: () => import("routes/LegalInfo/TermsofUse"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncDslComponent = Loadable({
	loader: () => import("routes/LegalInfo/dsl-page"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncSupportComponent = Loadable({
	loader: () => import("routes/Support/ticket/create-ticket"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncContactComponent = Loadable({
	loader: () => import("routes/Support/contact"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncSwitchUserComponent = Loadable({
	loader: () => import("routes/Support/Switch_user"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncProtocolPatternComponent = Loadable({
	loader: () => import("routes/protocol_pattern/pattern"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncUserComponent = Loadable({
	loader: () => import("routes/User/user"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncCompanyComponent = Loadable({
	loader: () => import("routes/company/company"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncSMTPComponent = Loadable({
	loader: () => import("routes/SMTP/smtp"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const userNotificationComponent = Loadable({
	loader: () => import("routes/UserNotifications/userNotification"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncListMaklersComponent = Loadable({
	loader: () => import("routes/Administration/overview"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncImportProductComponent = Loadable({
	loader: () => import("routes/Import_Products/import_product_step"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncDumpComponent = Loadable({
	loader: () => import("routes/Dump_DB/dump"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncAddMaklerComponent = Loadable({
	loader: () => import("routes/Administration/AddMakler"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncNotificationManuelleComponent = Loadable({
	loader: () => import("routes/Notifications/Add_notification"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncNotificationAutomatiqueComponent = Loadable({
	loader: () => import("routes/Notifications/Edit_scheduled"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncNotificationStatusComponent = Loadable({
	loader: () => import("routes/Notifications/Edit_OrderStatus"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});const AsyncNotificationSupportComponent = Loadable({
	loader: () => import("routes/Notifications/Edit_SupportUsage"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});const AsyncTicketsComponent = Loadable({
	loader: () => import("routes/Support/ticket/ticket-overview"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});const AsyncTicketDetailComponent = Loadable({
	loader: () => import("routes/Support/ticket/tickect-detail"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});




export {
	AsyncDashboardComponent,
	AsyncPropertiesComponent,
	AsyncImportRealEstateComponent,
	AsyncExportRealEstateComponent,
	AsyncTarifCalculatorComponent,
	AsyncProtocolComponent,
	AsyncKautionComponent,
	AsyncContractComponent,
	AsyncImpressumComponent,
	AsyncPolicyComponent,
	AsyncTermOfUseComponent,
	AsyncSupportComponent,
	AsyncContactComponent,
	AsyncSwitchUserComponent,
	AsyncProtocolPatternComponent,
	AsyncUserComponent,
	AsyncCompanyComponent,
	AsyncSMTPComponent,
	userNotificationComponent,
	AsyncListMaklersComponent,
	AsyncAddMaklerComponent,
	AsyncDumpComponent,
	AsyncImportProductComponent,
	AsyncKautionSettingsComponent,
	AsyncNotificationManuelleComponent,
	AsyncNotificationAutomatiqueComponent,
	AsyncNotificationStatusComponent,
	AsyncNotificationSupportComponent,
	AsyncCreatePropertyComponent,
	AsyncCreateAppartementComponent,
	AsynclistAppartementComponent,
	AsyncProtocolControlOverviewComponent,
	AsyncProtocolOverviewComponent,
	AsyncContractOverviewComponent,
	AsyncProtocolListComponent,
	AsyncContractListComponent,
	AsyncKautionListComponent,
	AsyncArchiveListComponent,
	AsyncDslComponent,
	AsyncAddPromoCodeComponent,
	AsyncTicketsComponent,
	AsyncTicketDetailComponent,
	AsyncProtocolRoomsComponent,
	AsyncKautionOverviewComponent,
	AsyncDeffectListComponent,
	AsyncDeffectDetailsComponent,
	AsyncProtocolRoomsComponent2,
	AsyncProtocolRoomsComponent3,
	AsyncNewDashboardComponent
};