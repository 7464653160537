import React, { useState } from "react";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FieldsAutocomplete from "../Autocomplete/FieldsAutocomplete";
import { v4 as uuidv4 } from "uuid";
import { Timestamp } from "firebase/firestore";
import { autoService } from "../../_services/autocomplete.service";
import InfoIcon from "@mui/icons-material/Info";
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import swal from "sweetalert";
import { DateFormatter } from "../../helpers/DateFormatter";
import { userService } from "../../_services/user.service";
const AutoDialog = ({
  show,
  setShow,
  handleInputChange,
  value,
  roomID,
  type,
  id,
  placeholder,
  level,
  index,
  counter_type,
  deffectNameID,
  secondIndex,
  isDraft,
  isdeffectDraft,
  handleRoomId,
  showCounters,
  setShowCounters,
  protocol,
  setProtocol,
  RealEstateObject,
  setRealEstateObject,
  idCounter,
  realname,
  indexItem
}) => {
  const FIELDTYPE = [
    "room_name",
    "room_generale_description",
    "image_description",
    "room_deffect_notice",
    "counter_location",
    "key_name",
    "key_notice",
    "product_installation_position",
    "counter_name",
    "controle_comment",
    "title"
  ];
  const [Autovalue, setAutovalue] = useState("");
  const [company_id, setCompany_id] = useState("");
  const [filteredOptions, setFiltredOptions] = useState([]);
  const [autoObject, setAutoObject] = useState({
    is_static: false,
    parent_ids: level == 0 || level == 1 ? [] : [roomID],
    sub_parent_ids: level == 0 ? [] : level == 1 ? [roomID] : [deffectNameID],
    level: level,
    marked_for_deletion: false,
    synchronized_remote: true,
    id: uuidv4(),
    company_id: company_id,
    created_at: Timestamp.now(),
    updated_at: Timestamp.now(),
    value: "",
    field_type:
      type == "product_installation_position"
        ? FIELDTYPE.indexOf("counter_location")
        : FIELDTYPE.indexOf(type),
  });


  function generateID(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const handleRealStateCounterName = (value) => {
    let property_counters = RealEstateObject?.property_counters;
    let secondcounterID = generateID(36);
    let fourthcounterID = generateID(20);
    let fifthcounterID = generateID(20);
    let sixthcounterID = generateID(20);
    let seventhcounterID = generateID(20);
    let eighthcounterID = generateID(20);
    let ninthcounterID = generateID(20);
    switch (value) {
      case "Wasserzähler":
        property_counters.push({
          counterLocation: "",
          counterName: "",
          counterNumber: "",
          counterType: "watter",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: fourthcounterID,
          is_double: "",
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;
      case "Heizöl":
        property_counters.push({
          counterLocation: "",
          counterName: "",
          counterNumber: "",
          counterType: "oil",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: fifthcounterID,
          is_double: "",
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;
      case "Rauchmelder":
        property_counters.push({
          counterLocation: "",
          counterName: "",
          counterNumber: "",
          counterType: "smokeDetector",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: sixthcounterID,
          is_double: null,
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;
      case "Elektronischer Heizkostenverteiler":
        property_counters.push({
          counterLocation: "",
          counterName: "",
          counterNumber: "",
          counterType: "electronicHot",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: seventhcounterID,
          is_double: "",
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;

      case "Heizkostenverteiler":
        property_counters.push({
          counterLocation: "",
          counterName: "",
          counterNumber: "",
          counterType: "hotness",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: ninthcounterID,
          is_double: null,
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;
      case "Gas":
        property_counters.push({
          counterLocation: "",
          counterName: "",
          counterNumber: "",
          market_Location_number: "",
          counterType: "gas",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: secondcounterID,
          is_double: "singleCounter",
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;
      default:
        property_counters.push({
          counterLocation: "",
          counterName: value,
          counterNumber: "",
          counterType: "other",
          createdAt: DateFormatter.initDate(),
          createdBy: userService.getId(),
          id: eighthcounterID,
          is_double: "",
          reProperty: "",
          realEstate: "",
          updatedAt: DateFormatter.initDate(),
        });
        break;
    }
    setRealEstateObject({
      ...RealEstateObject,
      property_counters: property_counters,
    });
  };
  // Handler for saving as draft
  const handleSaveDraft = async (value = null) => {
    var finalValue = "";
    if (typeof value === 'object' && value !== null) {
      finalValue = Autovalue
    } else {
      finalValue = value ;
    } 
    
    // Logic for saving as draft
    if (type == "counter_name") {
      if (RealEstateObject) {
        handleRealStateCounterName(finalValue);
      } else {
        handleAddCounterName(finalValue);
      }
    }
    setShow(false);
  };
  const handleAddCounterName = (value) => {
    let protocol_counter =
      protocol && protocol.protocol_counter ? protocol.protocol_counter : [];
    switch (value) {
      case "Wasserzähler":
        setShowCounters({
          ...showCounters,
          showWater: true,
        });
        if (protocol_counter) {
          protocol_counter.push({
            counter_name: null,
            counter_location: null,
            counter_number: null,
            counter_type: "watter",
            counter_is_double: null,
            counter_value_1: null,
            counter_value_2: null,
            counter_created_at: DateFormatter.initDate(),
            counter_id_object: null,
            counter_real_estate_id: null,
            counter_updated_at: DateFormatter.initDate(),
            property_counter_ref_id: null,
            counter_current_consumption: null,
            id: uuidv4(),
            image: {
              counter_image_local_path: null,
              counter_image_name: null,
              counter_image_remote_url: null,
              counter_image_time_stamp: null,
              counter_image_thumb: null,
              id: null,
            },
          });
        }
        break;
        case "Gas":
          setShowCounters({
            ...showCounters,
            showWater: true,
          });
          if (protocol_counter) {
            protocol_counter.push({
              counter_name: null,
              counter_number: null,
              counter_value_1: null,
              counter_value_2: null,
              market_location_number: "",
              counter_location: null,
              counter_type: "gas",
              counter_is_double: null,
              counter_created_at: DateFormatter.initDate(),
              counter_id_object: null,
              counter_real_estate_id: null,
              counter_updated_at: DateFormatter.initDate(),
              property_counter_ref_id: null,
              counter_current_consumption: null,
              id: uuidv4(),
              image: {
                counter_image_local_path: null,
                counter_image_name: null,
                counter_image_remote_url: null,
                counter_image_time_stamp: null,
                counter_image_thumb: null,
                id: null,
              },
            }
          );
          }
          break;
      case "Heizöl":
        setShowCounters({
          ...showCounters,
          showOil: true,
        });
        if (protocol_counter) {
          protocol_counter.push({
            counter_name: null,
            counter_location: null,
            counter_number: null,
            counter_type: "oil",
            counter_is_double: null,
            counter_value_1: null,
            counter_value_2: null,
            counter_created_at: DateFormatter.initDate(),
            counter_id_object: null,
            counter_real_estate_id: null,
            counter_updated_at: DateFormatter.initDate(),
            property_counter_ref_id: null,
            counter_current_consumption: null,
            id: uuidv4(),
            image: {
              counter_image_local_path: null,
              counter_image_name: null,
              counter_image_remote_url: null,
              counter_image_time_stamp: null,
              counter_image_thumb: null,
              id: null,
            },
          });
        }
        break;
      case "Rauchmelder":
        setShowCounters({
          ...showCounters,
          showSmokeDetector: true,
        });
        protocol_counter.push({
          counter_name: null,
          counter_location: null,
          counter_type: "smokeDetector",
          counter_is_double: null,
          counter_is_double: null,
          counter_created_at: DateFormatter.initDate(),
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: DateFormatter.initDate(),
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: uuidv4(),
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        });
        break;
      case "Elektronischer Heizkostenverteiler":
        setShowCounters({
          ...showCounters,
          showElectronicHot: true,
        });
        protocol_counter.push({
          counter_name: null,
          counter_number: null,
          counter_current_consumption: null,
          counter_value_1: null,
          counter_value_2: null,
          counter_location: null,
          counter_type: "electronicHot",
          counter_is_double: null,
          counter_created_at: DateFormatter.initDate(),
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: DateFormatter.initDate(),
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: uuidv4(),
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
          image1: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
          image2: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        });
        break;
      case "Heizkostenverteiler":
        setShowCounters({
          ...showCounters,
          showHotness: true,
        });
        protocol_counter.push({
          counter_name: null,
          counter_location: null,
          counter_number: null,
          counter_type: "hotness",
          counter_is_double: null,
          counter_value_1: null,
          counter_value_2: null,
          counter_created_at: DateFormatter.initDate(),
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: DateFormatter.initDate(),
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: uuidv4(),
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        });
        break;
      default:
        setShowCounters({
          ...showCounters,
          showOther: true,
        });
        protocol_counter.push({
          counter_name: value,
          counter_location: null,
          counter_name: value,
          counter_number: null,
          counter_type: "other",
          counter_is_double: null,
          counter_value_1: null,
          counter_value_2: null,
          counter_created_at: DateFormatter.initDate(),
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: DateFormatter.initDate(),
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: uuidv4(),
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        });
        break;
    }
    setProtocol({
      ...protocol,
      protocol_counter: protocol_counter,
    });
  };
  // Handler for finalizing
  const handleFinalize = async (picked) => {
    if (!picked) {
      if (type == "counter_name") {
        if (RealEstateObject) {
          handleRealStateCounterName(Autovalue);
        } else {
          handleAddCounterName(Autovalue);
        }
      }
    }
    // Logic for finalizing
    if (autoObject.value.trim() != "" && autoObject.value != null) {
      if (type == "room_name" && level == 1 && isDraft != "") {
        const id = uuidv4();
        const newOBj = {
          is_static: false,
          parent_ids: [],
          sub_parent_ids: [],
          level: 0,
          marked_for_deletion: false,
          synchronized_remote: true,
          id: id,
          company_id: autoObject.company_id,
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          value: isDraft,
          field_type: FIELDTYPE.indexOf("room_name"),
        };
        await autoService
          .Addfields(newOBj, autoObject.company_id)
          .then((result) => {
            autoObject.sub_parent_ids.splice(
              autoObject.sub_parent_ids.indexOf(roomID),
              1
            );
            autoObject.sub_parent_ids.push(id);
          });
      }
      if (
        type == "room_name" &&
        level == 2 &&
        isdeffectDraft != "" &&
        roomID != ""
      ) {
        const id = uuidv4();
        const newOBj = {
          is_static: false,
          parent_ids: [],
          sub_parent_ids: [roomID],
          level: 0,
          marked_for_deletion: false,
          synchronized_remote: true,
          id: id,
          company_id: autoObject.company_id,
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          value: isDraft,
          field_type: FIELDTYPE.indexOf("room_name"),
        };
        await autoService
          .Addfields(newOBj, autoObject.company_id)
          .then((result) => {
            autoObject.sub_parent_ids.splice(
              autoObject.sub_parent_ids.indexOf(deffectNameID),
              1
            );
            autoObject.sub_parent_ids.push(id);
          });
      }
      if (
        type == "room_name" &&
        level == 2 &&
        isdeffectDraft != "" &&
        isDraft != ""
      ) {
        const idRoom = uuidv4();
        const idDeffect = uuidv4();
        const newRoomOBj = {
          is_static: false,
          parent_ids: [],
          sub_parent_ids: [],
          level: 0,
          marked_for_deletion: false,
          synchronized_remote: true,
          id: idRoom,
          company_id: autoObject.company_id,
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          value: isDraft,
          field_type: FIELDTYPE.indexOf("room_name"),
        };
        const newdeffectOBj = {
          is_static: false,
          parent_ids: [],
          sub_parent_ids: [idRoom],
          level: 1,
          marked_for_deletion: false,
          synchronized_remote: true,
          id: idDeffect,
          company_id: autoObject.company_id,
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          value: isdeffectDraft,
          field_type: FIELDTYPE.indexOf("room_name"),
        };

        await autoService
          .Addfields(newRoomOBj, autoObject.company_id)
          .then((result) => {
            autoObject.parent_ids.splice(
              autoObject.parent_ids.indexOf(roomID),
              1
            );
            autoObject.parent_ids.push(idRoom);
          });
        await autoService
          .Addfields(newdeffectOBj, autoObject.company_id)
          .then((result) => {
            autoObject.sub_parent_ids.splice(
              autoObject.sub_parent_ids.indexOf(deffectNameID),
              1
            );
            autoObject.sub_parent_ids.push(idDeffect);
          });
        handleRoomId(idRoom, idDeffect);
      }
      await autoService
        .Addfields(autoObject, autoObject.company_id)
        .then((result) => {
          setShow(false);
          if (!picked) {
            swal({
              title: "HINWEIS",
              text: " Der Begriff wurde erfolgreich hinzugefügt und kann in Zukunft ausgewählt werden.",
              button: "OK",
              icon: "success",
            });
          }
        });
    }
  };

  // fill value
  const handlefillValue = (value, company_id) => {
    setAutovalue(value);
    setAutoObject({ ...autoObject, value: value, company_id: company_id });
  };

  // update value
  const update = async (
    type,
    value,
    company_id,
    oldValue,
    sub_parent_ids = null,
    parent_ids = null
  ) => {
    if (value != "" && value != null) {
      const updatedObject = {
        is_static: false,
        parent_ids: parent_ids ? parent_ids : [],
        sub_parent_ids: sub_parent_ids ? sub_parent_ids : [],
        level: level,
        marked_for_deletion: false,
        synchronized_remote: true,
        id: uuidv4(),
        company_id: company_id,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
        value: value,
        field_type: FIELDTYPE.indexOf(type),
      };
      await autoService
        .updateFields(updatedObject, company_id, oldValue)
        .then((result) => {
          swal("Begriff geändert", "", "success");
        });
    }
  };

  // Handler for closing the dialog
  const handleCloseDialog = () => {

    setShow(false);
  };

  return (
    <Dialog
      className={`after-cards  dialog ${show ? "show" : ""}`}
      style={{ backgroundColor: "transparent" }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: 800, // Optional: Adjust as needed
          // Optional: Adjust as needed
          maxHeight: "100%", // Optional: Adjust as needed
          overflowY: "auto",
        },
        "& .MuiDialogContent-root ": {
          minHeight: 600,
          overflowY: "hidden",
        },
      }}
      maxWidth="xs"
      open={show}
      onClose={() => setShow(false)}
    >
      <div className="dialog-content textfieldsdateplzObOw  pr-0 pl-0">
        {/* Close Icon */}
        <div className="close-icon" onClick={handleCloseDialog}>
            <HighlightOffIcon />
        </div>
        {/* Input with Autocomplete */}
        <DialogContent
          dividers
          sx={{
            //textTransform: "capitalize",
            borderBottom: "none",
            borderTop: "none",
          }}
        >
          <div className="row mt-3">
            <div className="col-md-10 div-status padding-room-tab status-title flex room-type pb-1">
              {
              (type == "room_name" && level == 0) ? (
                <>
                  Räume
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion Räume suchen. Sollten Sie einen Begriff nicht finden, kann dieses, entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden. ">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ) : type == "room_name" && level == 1 ? (
                <>
                  Objekte
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion Objekte suchen. Sollten Sie einen Begriff nicht finden kann dieses, entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden.">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ) : type == "room_name" && level == 2 ? (
                <>
                  Mängel
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion Mängel  suchen. Sollten Sie einen Begriff nicht finden, kann dieses, entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden. ">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ) : type == "key_name" ? (
                <>
                  Schlüssel
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion Schlüssel suchen. Sollten Sie einen Begriff nicht finden, kann dieses, entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden. ">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ) : type == "counter_location" ||
                type == "product_installation_position" || type == "counter_name" ? (
                <>
                  Zähler
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion Zähler suchen. Sollten Sie einen Begriff nicht finden, kann dieses, entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden. ">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ) : type == "room_generale_description" ? (
                <>
                  Raumdetails
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion allgemeine Raumdetails  suchen. Sollten Sie eine Beschreibung nicht finden, kann diese entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden.">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ) : type == "image_description" ? (
                <>
                  Bildkommentar
                  <div className="ml-1">
                    <Tooltip title="Sie können über die Suchfunktion gängige Kommentare suchen und benutzen. Sollten Sie ein Kommentar nicht finden, kann dieser entweder neu in die Liste aufgenommen werden oder einmalig für dieses Protokoll verwendet werden.">
                      <InfoIcon fontSize="medium" />
                    </Tooltip>
                  </div>
                </>
              ): type == "title" ? (
                <>
                  TITEL WÄHLEN
                </>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-12 padding-room-tab  flex-align-room room-type mt-4">
              <FieldsAutocomplete
                type={type}
                id={id} 
                value={value}
                placeholder={placeholder}
                index={index}
                handleChange={handleInputChange}
                multiline={false}
                roomID={roomID}
                deffectNameID={deffectNameID}
                level={level}
                update={update}
                fill={handlefillValue}
                counter_type={counter_type}
                secondIndex={secondIndex}
                setOptions={setFiltredOptions}
                showCounters={showCounters}
                setShowCounters={setShowCounters}
                protocol={protocol}
                setProtocol={setProtocol}
                idCounter={idCounter}
                realname={realname}
                setShow = {setShow}
                setAutovalue = {setAutovalue}
                handleSaveDraft = {handleSaveDraft}
                indexItem={indexItem}
              />
            </div>
          </div>
          <div hidden={autoObject.value.trim() == "" || autoObject.value.trim() == null }>
          <div className=" row w-100 d-flex justify-content-center">
            <div className="bewohnerButton col-md-5 ">
              <button type="button" onClick={handleSaveDraft}>
              {filteredOptions.find(
                (opt) => opt.value == autoObject.value
              ) ? <span> Übernehmen </span> : <span> Einmalig  übernehmen</span>}  
              </button>
            </div>
            <div
              className="bewohnerButton col-md-5"
              hidden={filteredOptions.find(
                (opt) => opt.value == autoObject.value
              )}
            >
              <button type="button" onClick={() => handleFinalize(false)}>
              Übernehmen & Speichern
              </button>
            </div>
          </div>
          </div>
        </DialogContent>
        {/* Save Options */}
      </div>
    </Dialog>
  );
};

export default AutoDialog;
