/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	AsyncProtocolComponent,
	AsyncProtocolRoomsComponent,
	AsyncProtocolRoomsComponent2,
	AsyncProtocolRoomsComponent3
} from 'routes';

const Protokol = ({ match }) => (
	<Switch>
		<Store>
			<FirebaseContextProvider>
				<Route path={`${match.url}/step`} component={AsyncProtocolComponent}></Route>
				<Route path={`${match.url}/rooms/details/:id`} component={AsyncProtocolRoomsComponent3}></Route>
				<Route path={`${match.url}/rooms/detail-view/:id`} component={AsyncProtocolRoomsComponent}></Route>
				</FirebaseContextProvider>
		</Store>

	</Switch>
)
export default Protokol;